<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>기술 문의</h3>
      </div>
      <div class="my-3">
        <mdb-tbl reponsive>
          <mdb-tbl-head style="background-color: #ececec;">
            <tr>
              <th colspan="4" style="font-size: 20px;">{{ title }}</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr>
              <th>등록일</th>
              <td>{{ reg_date }}</td>
              <th>분야</th>
              <td>{{ fields }}</td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{{ writer }}</td>
              <th>조회수</th>
              <td>{{ views }}</td>
            </tr>
<!--            <tr>-->
<!--              <th style="vertical-align: middle">파일</th>-->
<!--              <td colspan="3">-->
<!--                <ul>-->
<!--                  <li v-if="file_name !== ''">-->
<!--                    <a :href="file_name"> {{ file_url }}</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </td>-->
<!--            </tr>-->
            <tr>
              <td colspan="4" style="text-align: center">
                <div v-if="image_url !== ''">
                  <img :src="image_url" alt="img" style="max-width: 100%">
                </div>
                <div class="my-3" v-if="contents !== ''">
                  {{ contents }}
                </div>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </mdb-col>
    <mdb-container>
      <mdb-row>
        <mdb-col>
          <mdb-input placeholder="작성자명을 입력하세요. (미입력시 익명)" basic class="mb-3" v-model="reply_writer">
            <span class="input-group-text" slot="prepend"><i class="fas fa-user prefix"></i></span>
          </mdb-input>
          <mdb-input type="textarea" basic v-model="reply">
            <span class="input-group-text" slot="prepend"> <i class="fas fa-pencil-alt prefix"></i></span>
          </mdb-input>
        </mdb-col>
        <mdb-btn style="background-color: #4b4242 !important;" @click="setWriteReply">댓글달기</mdb-btn>
      </mdb-row>
    </mdb-container>
    <mdb-col class="reply-form my-5">
      <div>
        <ul>
          <Reply :replies="replies[i]" v-for="(a,i) in replies" :key="i" @update="getRepliesData"/>
        </ul>
      </div>
    </mdb-col>
    <div class="mb-5 text-right" style="text-align: center" v-show="uid === this.$store.state.firebase_uid">
      <mdb-btn outline="danger" small icon="trash" @click="deletePost">삭제</mdb-btn>
    </div>
  </mdb-container>
</template>

<script>
import {mdbRow, mdbInput, mdbCol, mdbContainer, mdbTbl, mdbTblBody, mdbTblHead, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";
import Reply from "./Reply";

export default {
  name: "TechnicalView",
  data() {
    return {
      id: this.$route.query.id,
      contents: '',
      files: [],
      image_url: '',
      reg_date: '',
      title: '',
      views: 0,
      views_count: 0,
      writer: '',
      reply_contents: '',
      reply_reg_date: '',
      reply_writer: '',
      replies: [],
      reply: '',
      fields: '',
      file_name: '',
      file_url: '',
      uid:'',
    }
  },
  components: {
    mdbRow,
    mdbInput,
    Reply,
    mdbBtn,
    mdbContainer,
    mdbTblHead,
    mdbCol,
    mdbTbl,
    mdbTblBody
  },
  mounted() {
    this.onFirebaseLoadData();
    this.setViewCountAdd();
  },
  methods: {
    setViewCountAdd() {
      const self = this;
      const db = firebase.firestore();

      const docRef = db.collection('technicalBoard').doc(self.id);

      db.runTransaction((transaction) => {
        return transaction.get(docRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          const newViews = sfDoc.data().views + 1;
          if (newViews <= 1000000) {
            transaction.update(docRef, {views: newViews});
            return newViews;
          } else {
            return Promise.reject("Sorry! Population is too big.");
          }
        });
      }).then((newViews) => {
        console.log("Population increased to ", newViews);
      }).catch((err) => {
        // This will be an "population is too big" error.
        console.error(err);
      });
    },
    onFirebaseLoadData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("technicalBoard");

      // 본문을 가져와
      docRef.doc(self.id).get()
          .then(function (querySnapshot) {
            const _data = querySnapshot.data();
            self.contents = _data.contents;
            // if (!self.isEmpty(_data.files)) {
            //   self.files = Object.entries(_data.files);
            //   self.file_url = Object.entries(_data.files)[0][0];
            //   self.file_name = Object.entries(_data.files)[0][1];
            // }
            self.image_url = _data.image_url;
            const date = new Date(_data.reg_date.seconds * 1000);
            self.reg_date = self.getParseDate(date);
            self.title = _data.title;
            self.views = _data.views;
            self.writer = _data.writer;
            self.fields = _data.fields;
            self.uid = _data.uid;
          });

      self.getRepliesData();

    },
    isEmpty(param) {
      return Object.keys(param).length === 0;
    },
    getRepliesData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("technicalBoard");
      self.replies = [];

      // 댓글을 가져와
      docRef.doc(self.id).collection('replies').orderBy("reply_reg_date").get()
          .then((querySnapshot) => {
            self.totCount = querySnapshot.size
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data["post_id"] = self.id;
              _data["reply_id"] = doc.id;
              const date = new Date(_data.reply_reg_date.seconds * 1000);
              _data.reply_reg_date = self.getParseDate(date);
              self.replies.unshift(_data);

              // console.log(self.replies);
            });
          })
    },

    setWriteReply() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        reply_contents: self.reply,
        reply_writer: self.reply_writer,
        uid: self.$store.state.firebase_uid,
        reply_reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      const docRef = db.collection("technicalBoard");

      docRef.doc(self.id).collection('replies').add(data)
          .then(() => {
            alert('댓글을 등록했습니다.');
            self.getRepliesData();
            self.reply_writer = '';
            self.reply = '';
          })
          .catch((err) => {
            console.log(err)
          })
    },

    /**
     * dataTime => 2000-01-01 10:30:20
     * @param date
     * @returns {string}
     */
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    },
    deletePost() {
      const self = this;
      const confirmDialog = confirm('정말로 삭제하시겠습니까?');
      if (!confirmDialog) {
        return;
      }
      const db = firebase.firestore();
      const docRef = db.collection("technicalBoard");
      docRef.doc(self.id)
          .delete()
          .then(() => {
            this.$router.replace('/technicalList').catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

th {
  background-color: #ececec;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reply-form li {
  padding: 20px 16px;
  border-bottom: 1px solid #e3e5e8;
  list-style: none;
  font-size: 13px;
}

.member_info {
  margin-bottom: 10px;
}

</style>
